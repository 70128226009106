import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Header from "../Header/Header";
import Link from "@material-ui/core/Link";
import Launch from "@material-ui/icons/Launch";
import Typography from "@material-ui/core/Typography";
import { getRequest } from "../../apis/axiosAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { userLogging } from "../../apis/userLog";
import { getDeviceType } from "../../apis/util";
import UserMasterManagement from "./UserMasterManagement";
import Snackbar from '@material-ui/core/Snackbar';
import { getFirebaseUserEmail } from "../../firebase";

let appInfo: any = {};
const iconDir = `${window.location.origin}/image/icons/`;
const opview = "ofc-counselling";
const tenpoCheck = "tenpo-check";
const ofcCounselling = "acn-ofc-counselling";
const ofcDashboard = "ofc-dashboard";
const shopItemTag = "shop-item-tag";
const chatAi = "chatAi";

const getAppInfo = (appName: string, typeName: string): string => {
  const isUserToken = "is_usertoken";
  if (typeName === "auth-url") {
    return appInfo[appName].auth_url;
  } else if (typeName === "site-url") {
    return appInfo[appName].site_url;
  } else if (typeName === "app-name") {
    return appInfo[appName].app_name;
  } else if (
    typeName === "token" &&
    appName.startsWith(ofcCounselling)
  ) {
    return "usertoken";
  } else if (typeName === "token" && appName.startsWith(shopItemTag)) {
    return "usertoken";
  } else if (typeName === "token" && appName.startsWith(chatAi)) {
    return "usertoken";
  } else if (typeName === "token" && appInfo[appName][isUserToken]) {
    return "usertoken";
  } else if (typeName === "token") {
    return "token";
  }
  return "";
};

// ユーザー種別・環境(デバイスなど)に応じて利用可能なアプリケーションを設定する
const setApplication = (
  apps: any,
  mail_address: string = "",
  job_category_code_OA: string = "",
  department_code_OA: string = ""
) => {
  // デジタルツール上のボタン非表示
  let exclusionApps: string[] = [];
  // iPhoneの場合
  if (getDeviceType() === "iphone") {
    exclusionApps = [opview, tenpoCheck, ofcCounselling, ofcDashboard];
  } else {
    // その他デバイス
    exclusionApps = [ofcDashboard];
  }
  apps = apps.filter((app: string) => !exclusionApps.includes(app));
  return apps;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      width: "100vw",
    },
    rootTable: {
      marginTop: "5vh",
    },
    button: {
      color: "#fff",
      backgroundColor: "#ef942a",
      width: "40vw",
      height: "15vh",
      margin: "10px",
      "&:hover": {
        background: "#ef942a",
      },
      textTransform: "none",
    },
    blueButton: {
      color: "#fff",
      backgroundColor: "#007bff",
      width: "40vw",
      height: "8vh",
      margin: "10px",
      "&:hover": {
        background: "#007bff",
      },
    },
    gap: {
      height: "15px",
    },
    homePadding: {
      padding: "10px",
    },
    homeContents: {
      height: "90%",
    },
    homeMainMenu: {
      fontWeight: "bold",
      fontSize: "20px",
      color: "white"
    },
    homeButton: {
      borderRadius: "10px",
      width: "470px",
      height: "54px",
      backgroundColor: "#00CC99",
      "&:hover": {
        background: "#00CC99",
      },
    },
    homeButtonDouble: {
      borderRadius: "10px",
      width: "470px",
      height: "108px",
      backgroundColor: "#00CC99",
      "&:hover": {
        background: "#00CC99",
      },
    },
    homeButtonFont: {
      color: "white",
    },
    homeMenuIcon: {
      height: "20px",
      paddingRight: "10px",
    },
    homeAccordion: {
      borderRadius: "10px",
      width: "470px",
    },
    accordionHeader: {
      backgroundColor: "#00CC99",
      color: "white",
      borderRadius: "10px",
    },
    sectionItem: {
      marginLeft: "20px",
      underline: "hover",
      variant: "caption",
      fontSize: "20px",
      cursor: "pointer",
      color: "#404040",
    },
  })
);

const Home: React.FC = (props: any) => {
  const classes = useStyles();
  const [displayMode, setDisplayMode] = React.useState("initial");
  const [apps, setApps] = React.useState([]);
  const [isAnotherLoginLoading, setIsAnotherLoginLoading] =
    React.useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    // ローカルの場合はAPIサービスを参照する
    const userUrl =
      process.env.REACT_APP_URL_API! + "users/info/get-user-information";

    const moveAuthUrlFromUrl = (
      appName: string,
      paramerters: string = "",
      userJson: any
    ) => {
      if (userJson === undefined || userJson["apps"] === undefined) {
        setIsAnotherLoginLoading(false);
        setDisplayMode("noapps");
        // 権限がない場合ユーザーログに出力する
        userLogging("権限エラー", "レスポンスなし");
      } else if (userJson["apps"].indexOf(appName) === -1 && appName !== "") {
        setIsAnotherLoginLoading(false);
        setDisplayMode("noapps");
        // 権限がない場合ユーザーログに出力する
        userLogging("権限エラー", "アプリ設定なし");
      } else {
        const authUrl = getAppInfo(appName, "auth-url");
        getRequest(authUrl, {})
          .then((response) => {
            let token: string = "";
            if (authUrl.indexOf("v1/user-auth/usercheck") !== -1) {
              token = response.dataList[0].token;
            } else {
              token = response;
            }
            const paramertersString =
              paramerters === "" ? paramerters : "&" + paramerters;
            const url = `${getAppInfo(
              appName,
              "site-url"
            )}?token=${token}${paramertersString}`;
            window.location.href = url;
          })
          .catch(() => {
            console.log("MoveAuthUrlError2");
          });
      }
    };

    // デジタルツール画面表示
    setIsAnotherLoginLoading(true);
    getRequest(userUrl, {})
      .then((response: any) => {
        // ユーザー情報取得でエラーの場合最初に戻す
        if (response.statusCode === "900") {
          userLogging("ユーザー情報取得エラー", "レスポンス無し(ステータス900)");
          props.history.push("/");
        }
        if (response.success === false) {
          userLogging("ユーザー情報取得エラー", "レスポンス無し(処理失敗)");
          props.history.push("/");
        }
        const userJson = JSON.parse(response.dataList);
        userLogging("ユーザー情報:", JSON.stringify(userJson));
        if (userJson && userJson["application_infomation"]) {
          for (const key in userJson["application_infomation"]) {
            appInfo[key] = userJson["application_infomation"][key];
          }
        } else {
          // userJsonおよびuserJson["application_infomation"]が存在しない場合、エラー回避のため終了する
          return;
        }
        setIsAnotherLoginLoading(false);
        let apps: any = [];
        if (
          props.location.state !== undefined &&
          "selectedApplication" in props.location.state &&
          props.location.state.selectedApplication !== ""
        ) {
          // アプリケーションが選択されており、デジタルツール画面を表示せずにそのままリダイレクトする場合
          // 認証後URL+パラメータへ移動する
          const appName: string = props.location.state.selectedApplication;
          moveAuthUrlFromUrl(
            appName,
            props.location.state.paramerters,
            userJson
          );
          return;
        }
        // 下記は通常デジタルツール画面
        if (userJson["apps"] === undefined) {
          // 空のユーザーは閲覧可能項目なし
          apps = [];
        } else if (
          userJson["mail_address"] === undefined ||
          userJson["job_category_code_OA"] === undefined
        ) {
          // メールアドレスや職種コードがないユーザーは配列を付与(検証環境向け)
          apps = userJson["apps"];
        } else {
          apps = setApplication(
            userJson["apps"],
            userJson["mail_address"],
            userJson["job_category_code_OA"],
            userJson["department_code_OA"]
          );
        }
        setDisplayMode("apps");
        setApps(apps);
        userLogging("利用可能なアプリ:", apps);
      })
      .catch((error: any) => {
        userLogging("ユーザー情報取得エラー:", error);
        console.log(error);
      });
  }, []);

  const isDisplayModes = (): boolean => {
    return displayMode !== "initial";
  };

  const getAppNameIcon = (appName: string, typeName: string) => {
    const applicationName: string = appInfo[appName].app_name;
    if (applicationName.match(/OPView/) || applicationName.match(/OPVIEW/)) {
      return (
        <Link className={classes.homeButtonFont}>
          <img
            src={iconDir + "op_view_icon.png"}
            alt=""
            className={classes.homeMenuIcon}
          />
          {applicationName}
        </Link>
      );
    } else if (applicationName.match(/店舗確認・One Vision/)) {
      return (
        <Link className={classes.homeButtonFont}>
          <img
            src={iconDir + "tenpo_check_icon.png"}
            alt=""
            className={classes.homeMenuIcon}
          />
          {applicationName}
        </Link>
      );
    }
    return <Link className={classes.homeButtonFont}>{applicationName}</Link>;
  };

  const getSevenViewDemoAccordion = (app: string, index: number) => {
    // アコーディオンのみ生成を行い、各デモ環境のボタン生成処理をスキップする
    if (app !== "sevenview-demo") {
      return "";
    }
    const accordionList = appInfo[app].accordion_list;
    return (
      <React.Fragment key={index}>
        <Grid className={classes.gap}></Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.homePadding}
        >
          <Accordion
            className={classes.homeAccordion}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordionHeader}
            >
              <Grid container justify="center">
                <Typography className={classes.homeMainMenu}>
                  {getAppNameIcon(app, "app-name")}
                </Typography>
              </Grid>
            </AccordionSummary>
            {(
              accordionList.map((app: string, index: number) => (
                <React.Fragment key={index}>
                  <AccordionDetails>
                    <Link
                      className={classes.sectionItem}
                      onClick={() => {
                        userLogging("アプリ遷移ボタン押下:" + app, "");
                        let authUrl = getAppInfo(app, "auth-url");
                        getRequest(authUrl, {})
                          .then((response) => {
                            const paramerters = props.location.state.paramerters;
                            const paramertersString =
                              paramerters === "" ? paramerters : "&" + paramerters;
                            let token: string = "";
                            if (authUrl.indexOf("v1/user-auth/usercheck") !== -1) {
                              token = response.dataList[0].token;
                            } else {
                              token = response;
                            }
                            const url = `${getAppInfo(
                              app,
                              "site-url"
                            )}?token=${token}${paramertersString}`;
                            window.open(url);
                          })
                          .catch(() => {
                            console.log("setAccessTokenError");
                          });
                      }}
                    >
                      <Typography>{getAppInfo(app, "app-name")}</Typography>
                    </Link>
                  </AccordionDetails>
                </React.Fragment>
              ))
            )}
          </Accordion>
        </Grid>
      </React.Fragment>
    );
  };

  const isDisplayBackground = (): boolean => {
    if (displayMode === "initial") {
      return false;
    }
    return true;
  };

  const userInfoApps = (app: any) => {
    if (Object.keys(appInfo).indexOf(app) === -1) {
      return false;
    }
    return true;
  };

  /**
   * ユーザーがの権限管理の権限を保持しているか確認する
   * 権限管理項目を持ち、そのユーザーであるか判定する
   */
  const userInfoAuthApps = (app: string) => {
    if (Object.keys(appInfo).indexOf(app) === -1) {
      // 項目がない場合
      return false;
    }
    const key = "administrator";
    if (!(key in appInfo[app])) {
      // 権限管理ユーザーの項目がない場合
      return false;
    }
    const userMailAddress = getFirebaseUserEmail();
    if (appInfo[app][key].includes(userMailAddress)) {
      // 権限管理ユーザーに含まれる場合
      return true;
    }
    return false;
  };

  const moveAuthUrl = (appName: string, paramerters: string = "",
    windowFlg: boolean) => {
    if (appName === "customer-360-viewer") {
      // Consumer360(家計簿PJ)への遷移を行う
      if (windowFlg) {
        window.open("customer-360-viewer", '_blank', "noreferrer");
      } else {
        props.history.push("customer-360-viewer");
      }
      return;
    }
    if (windowFlg) {
      setOpen(true);
    }

    let authUrl = getAppInfo(appName, "auth-url");
    if (authUrl === "") {
      // 「auth-url」が空の場合認証トークンを取得しない
      const paramertersString: string =
        paramerters === "" ? paramerters : "?" + paramerters;
      const url = getAppInfo(appName, "site-url") + paramertersString;
      if (windowFlg) {
        window.open(url, '_blank', "noreferrer");
      } else {
        window.location.href = url;
      }
      return;
    }
    getRequest(authUrl, {})
      .then((response) => {
        let token: string = "";
        const paramertersString: string =
          paramerters === "" ? paramerters : "&" + paramerters;
        if (authUrl.indexOf("v1/user-auth/usercheck") !== -1) {
          token = response.dataList[0].token;
        } else {
          token = response;
        }
        const tokenName = getAppInfo(appName, "token");
        const url = `${getAppInfo(
          appName,
          "site-url"
        )}?${tokenName}=${token}${paramertersString}`;

        if (windowFlg) {
          window.open(url, '_blank', "noreferrer");
        } else {
          window.location.href = url;
        }
      })
      .catch(() => {
        console.log("MoveAuthUrlError");
      });
  };

  const displayOtherButton = (app: string, index: number) => {
    if (app.match(/sevenview-demo/)) {
      return getSevenViewDemoAccordion(app, index);
    } else if (app.match(/tenpo-check/)) {
      return getDoubleLinesAccordion(app, "店舗確認表・ONE VISION", "店舗衛生点検・店舗経営診断", index);
    } else if (app.match(/sej-chat-gpt/)) {
      return getDoubleLinesAccordion(app, "セブンイレブンAIライブラリー", "SEJ Chat AI", index);
    }
  }

  /**
   * 複数行のタイトルを入れる
   * @param app 
   * @param index 
   * @returns 
   */
  const getUserMaster = (app: string, index: number) => {
    const appName = appInfo[app].app_name
    return (
      <UserMasterManagement appId={app} appName={appName} index={index} />
    )
  }

  /**
   * 複数行のタイトルを入れる
   * @param app 
   * @param titleOne 
   * @param titleSecond 
   * @param index 
   * @returns 
   */
  const getDoubleLinesAccordion = (app: string, titleOne: string, titleSecond: string, index: number) => {
    return (
      <React.Fragment key={index}>
        {userInfoApps(app) && (
          <>
            <Grid className={classes.gap}></Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.homePadding}
            >
              <Button
                className={classes.homeButtonDouble}
              >
                <Grid container>
                  <Grid item xs={11}
                    onClick={() => {
                      userLogging("アプリ遷移ボタン押下:" + app, "");
                      moveAuthUrl(app, props.location.state.paramerters, false);
                    }}
                  >
                    <Typography className={classes.homeMainMenu}>
                      <span className={classes.homeMainMenu}>
                        {titleOne}
                        <br />
                        {titleSecond}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1}
                    onClick={() => {
                      userLogging("アプリ遷移ボタン押下:" + app, "");
                      moveAuthUrl(app, props.location.state.paramerters, true);
                    }}
                  ><Launch />
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </>
        )}
      </React.Fragment>
    )
  }

  const appsButtons = apps.map((app: string, index) =>
    app.match(/sevenview-demo|tenpo-check|sej-chat-gpt/) ? (
      displayOtherButton(app, index)
    ) : (
      <React.Fragment key={index}>
        {userInfoApps(app) && (
          <>
            <Grid className={classes.gap}></Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.homePadding}
            >
              <Button
                className={classes.homeButton}
              >
                <Grid container>
                  <Grid item xs={11}
                    onClick={() => {
                      userLogging("アプリ遷移ボタン押下:" + app, "");
                      moveAuthUrl(app, props.location.state.paramerters, false);
                    }}
                  >
                    <Typography className={classes.homeMainMenu}>
                      {getAppNameIcon(app, "app-name")}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}
                    onClick={() => {
                      userLogging("アプリ遷移ボタン押下:" + app, "");
                      moveAuthUrl(app, props.location.state.paramerters, true);
                    }}
                  ><Launch />
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </>
        )}
      </React.Fragment>
    )
  );

  const appsAuthButtons = apps.map((app: string, index) =>
    userInfoAuthApps(app) ? (
      (
        <React.Fragment key={("auth-" + index.toString())}>
          {getUserMaster(app, index)}
        </React.Fragment>
      )
    ) : (
      <React.Fragment key={("auth-" + index.toString())}></React.Fragment>
    )
  );

  const displayModes = () => {
    switch (displayMode) {
      case "blank":
        return <></>;
      case "noapps":
        return (
          <div>
            <p>閲覧権限がありません</p>
          </div>
        );
      case "apps":
        return (
          <Grid container item xs={12} justify="center">
            {appsButtons}
            {appsAuthButtons}
          </Grid>
        );
    }
  };

  /**
   * トーストメッセージ(Snackbar)を閉じるときに発生するイベント
   * @param event 
   * @param reason 
   * @returns 
   */
  const handleCloseMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {isAnotherLoginLoading ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress
            size={"40vh"}
            style={{
              color: "#ccc",
              top: "30vh",
              position: "absolute",
            }}
          />
        </div>
      ) : (
        <Grid
          container
          className={isDisplayBackground() ? classes.root : ""}
          alignItems="flex-start"
        >
          {isDisplayModes() ? (
            <>
              <Grid
                container
                alignItems="flex-start"
                justify="center"
                className={classes.homeContents}
              >
                <Grid container>
                  {isDisplayBackground() ? (
                    <>
                      <div id="userInfoDiv" style={{ visibility: "hidden" }} />
                      <Header titleName={"デジタルツール"} />
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid
                    container
                    alignItems="flex-start"
                    justify="center"
                    className={classes.homeContents}
                  >
                    <Grid className={classes.rootTable}>{displayModes()}</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Snackbar
                open={open}
                message="右側の別タブアイコンを押下すると別タブで開かれます"
                autoHideDuration={1500}
                onClose={handleCloseMessage}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transitionDuration={{ enter: 10, exit: 150 }}
              />
            </>
          ) : (
            <></>
          )}
        </Grid>
      )}
    </>
  );
};

export default Home;
