import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { auth, microsoft365Login, getFirebaseAuthToken } from "./firebase";
import { login, logout } from "./features/userSlice";
import { getParam, getParametersString } from "./apis/util";
import axios from "axios";

const App: React.FC = (props: any) => {
  const url: URL = new URL(window.location.href);
  const params = url.searchParams;
  let startMenu = "home";
  switch (url.pathname) {
    case "/login":
      startMenu = "login";
      break;
    case "/home":
      startMenu = "home";
      break;
    case "/customer-360-viewer":
      startMenu = "customer-360-viewer";
      break;
  }
  const dispatch = useDispatch();

  useEffect(() => {
    const unSub = auth.onAuthStateChanged((authUser) => {
      // アプリ遷移などの情報がある場合ローカルストレージに保存する
      setLocalStorage(window.location);
      if (authUser) {
        dispatch(
          login({
            uid: authUser.uid,
            displayName: authUser.displayName,
          })
        );
        const localStorageResult = getLocalStorage(window.location);
        const localStorageApplicationId = localStorageResult.applicationId;
        const localStorageParameters = localStorageResult.parameters;
        if (localStorageApplicationId !== "" && localStorageParameters !== "") {
          const selectedApplication = localStorageApplicationId;
          const paramerters = localStorageParameters;
          props.history.push({
            pathname: startMenu,
            state: { selectedApplication, paramerters },
          });
          return
        }
        const selectedApplication = getParam(
          "application",
          window.location.href
        );
        const paramerters = getParametersString(window.location.search);
        props.history.push({
          pathname: startMenu,
          state: { selectedApplication, paramerters },
        });
      } else {
        dispatch(logout());
        if (
          process.env.REACT_APP_MODE &&
          process.env.REACT_APP_MODE.indexOf("mail-auth") !== -1
        ) {
          props.history.push("login");
        } else if (params.get("auth") === "firebase") {
          microsoft365Login();
        } else {
          // msal認証経由(iOS16.1ログイン障害回避のため)
          iOsInitialOperation();
        }
      }
    });
    return () => {
      unSub();
    };
  }, [dispatch, props.history]);
  return <></>;
};
export default App;

// iOSでの初期処理
const iOsInitialOperation = async () => {
  const host = process.env.REACT_APP_MODE !== "local" ? "./" : "http://localhost:19002/"

  // 現在のURLを取得し、GAE環境であれば新ドメインに転送する
  const currentURL = window.location.hostname;
  if (currentURL === "sej-sso-ms365.an.r.appspot.com") {
    const newHost = "https://digital-tools.jp";
    window.location.href = newHost + window.location.pathname + window.location.search;
    return
  } else if (currentURL === "sej-sso-ms365-sub.dt.r.appspot.com") {
    const newHost = "https://sub.digital-tools.jp";
    window.location.href = newHost + window.location.pathname + window.location.search;
    return;
  } else if (currentURL === "sso-ms365-test.an.r.appspot.com") {
    const newHost = "https://stg.digital-tools.jp";
    window.location.href = newHost + window.location.pathname + window.location.search;
    return;
  } else if (currentURL === "sej-sso-ms365-test-sub.dt.r.appspot.com") {
    const newHost = "https://stg-sub.digital-tools.jp";
    window.location.href = newHost + window.location.pathname + window.location.search;
    return;
  } else if (currentURL === "sso-ms365-dev.an.r.appspot.com") {
    const newHost = "https://dev.digital-tools.jp";
    window.location.href = newHost + window.location.pathname + window.location.search;
    return;
  }

  // 次へボタンでログインを行う
  await axios
    .get(host + "msauth/check", {
      headers: { Authorization: "Bearer " + (await getFirebaseAuthToken()) },
    })
    .then((result) => {
      // ここのモジュールは開発環境でしっかり動かすためにはCORSを突破するために「npm run build-dev」でビルドし、
      // サーバーサイドの「src\main\resources\static」に配置して検証する必要があるので注意
      if (result.data.is_login) {
        // MS365ログイン済かつFirebase未ログインの場合でトークン経由でFirebaseログインを行う
        if ("firebase_token" in result.data) {
          auth
            .signInWithCustomToken(result.data.firebase_token)
            .then(() => {
              // Firebaseに認証されるとリダイレクトが起動して最初の流れに移る
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(errorCode + ":" + errorMessage);
            });
        }
      } else {
        // 未ログインの場合
        window.location.href = host + "auth/sign_in";
      }
    });
};

/**
 * ローカルストレージへの値の保存を行う
 * 
*/
const setLocalStorage = (location: Location) => {
  const selectedApplication = getParam(
    "application",
    location.href
  );
  if (selectedApplication === "") {
    return;
  }
  const parameters = getParametersString(location.search);
  // 現在のunixtime（秒単位）
  const currentTime = Math.floor(Date.now() / 1000);

  // パラメータとタイムスタンプを一緒に保存
  localStorage.setItem('selected-application', JSON.stringify({
    selectedApplication: selectedApplication,
    parameters: parameters,
    timestamp: currentTime
  }));
}

/**
 * ローカルストレージより値の取得を行う
 * 
*/
const getLocalStorage = (location: Location) => {
  const blank = {
    applicationId: "",
    parameters: ""
  }
  const selectedApplicationFromUrl = getParam(
    "application",
    location.href
  );
  if (selectedApplicationFromUrl !== "") {
    // URLにパラメータが設定されている場合は値を渡さない
    return blank;
  }
  // ローカルストレージからデータを取得
  const storedData = localStorage.getItem('selected-application');
  if (!storedData) {
    // ローカルストレージが設定されていない場合は値を渡さない
    return blank;
  }
  // 現在のunixtime（秒単位）
  const currentTime = Math.floor(Date.now() / 1000);
  const { parameters, timestamp, selectedApplication } = JSON.parse(storedData);
  const seconds = 60;
  if (currentTime - timestamp > seconds) {
    // タイムスタンプが指定時間外の場合は値を渡さない
    localStorage.removeItem('selected-application');
    return blank;
  }
  if (typeof parameters !== 'string' || typeof selectedApplication !== 'string') {
    // `parameters` と `selectedApplication` のいずれかが文字列でない場合は値を渡さない
    localStorage.removeItem('selected-application');
    return blank;
  }
  // アプリケーションIDとパラメータを組み合わせて返す
  const result = {
    applicationId: selectedApplication,
    parameters: parameters
  };
  // ローカルストレージをクリーンアップ（必要に応じて削除）
  localStorage.removeItem('selected-application');

  // 有効なデータを返却
  return result;
}